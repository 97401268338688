$night-1: #2E3440;
$night-2: #3B4252;
$night-3: #434C5E;
$night-4: #4C566A;

$snow-1: #D8DEE9;
$snow-2: #E5E9F0;
$snow-3: #ECEFF4;

$frost-1: #8FBCBB;
$frost-2: #88C0D0;
$frost-3: #81A1C1;
$frost-4: #5E81AC;

$red:    #BF616A;
$orange: #D08770;
$yellow: #EBCB8B;
$green:  #A3BE8C;
$purple: #B48EAD;

::selection {
  background: $frost-1;
  color: $night-1;
}
::-moz-selection {
  background: $frost-1;
  color: $night-1;
}

hr {
  width: 90vw;
  border: 2px solid $night-1;
  border-radius: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 50px;
  margin-bottom: 50px;
}

li {
  list-style: "–  ";
}

.App {
  height: 100%;
  max-width: 100vw;
  color: $night-2;
  display: flex;
  flex-direction: column;
  
}

.App-link {
  text-decoration: underline;
  color: $snow-1;
  transition: all .25s ease-in-out;
}

.App-link:hover, .App-link:focus {
  color: $frost-2;
}

.App-header {
  background-color: $night-1;
  min-height: 2.4em;
  font-size: calc(min(26vmin, 18vw));
  text-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  color: $snow-1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 4%;
}


.App-divider {
  height: 10px;
  background: repeating-linear-gradient(
    90deg,
    $red,
    $orange,
    $orange,
    $yellow,
    $yellow,
    $green,
    $green,
    $frost-4,
    $frost-4,
    $purple,
    $purple,
    $red
  );

  background-size: 200%;
  animation: Rainbow 5s infinite linear;
}

@keyframes Rainbow {
  100% {
    background-position: 200%;
  }
}

.App-body {
  width: 100%;
  background-color: $night-2;
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

@keyframes in {
  0% {
    opacity: 0;
    margin-top: 15%;
  }
  100% {
    opacity: 1;
    margin-top: 18px;
  }
}

.item {
  color: $snow-1;
  background-color: $night-1;
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: calc(12px + 1vmin);
  line-height: 1.5em;
  margin: 18px;
  padding: 36px;
  border-radius: 18px;
  animation: in 1.5s ease;
}

.project {
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all .25s ease-in-out;
}

.project-img {
  margin-right: 3vmin;
}

.project-title {
  font-size: calc(16px + 1vmin);
}

.barkloaf {
  width: 125px;
  height: 100px;
  background: linear-gradient(
    90deg,
    $red,
    $orange,
    $yellow,
    $green,
    $frost-4,
    $purple
  );
}

.project:hover, .project:focus {
  outline-color: $frost-3;
  outline-style: solid;
  transform: scale(1.05);
  margin: 25px;
  background-color: $night-3; 
}

.tech-root {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techs {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tech {
  margin: 12px;
  transition: all .25s ease-in-out;
}

.tech:hover, .tech:focus {
  transform: scale(1.25);
  margin-left: 20px;
  margin-right: 20px;
}

.about {
  max-width: 75vw;
}

.about-title {
  font-size: calc(22px + 1vmin);
}

.about-top {
  padding-bottom: 0.25em;
}

.about-mid {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

.about-bottom {
  padding-top: 0.25em;
}

.App-footer {
  min-height: max-content;
  width: 100%;
  background-color: $night-1;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-link {
  margin-left: 10px;
  margin-right: 10px;
  transition: all .25s ease-in-out;
}

.footer-link:hover, .footer-link:focus {
  transform: scale(1.15);
  margin-left: 18px;
  margin-right: 18px;
}